import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Web3serviceService } from 'src/app/service/web3service.service';
import Web3 from "web3";
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';


const {
  HOME_CHAIN_ID,
  FOREIGN_CHAIN_ID
} = environment

interface NetworkData {
  name: string;
  image: string;
  website: string;
  symbol: string;
  explorer: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  //  networkData: any[];
   nativeCoinPrice: any;
   nativeCoinBalance: any;
   btntCoinBalance:any;
   btntAmount: Number = 0;
   btntTransferAmount: any;
   isDisbaled: boolean = false;
   btntCoinBscBalance: any;
   chainId: any;
   disabled: boolean = false;

  constructor(private web3serviceService:Web3serviceService, private router: Router) {}
  ngOnInit() {
    this.connectMetamask()
    this.getNativeCoinPrice() 
    this.getNativeCoinBalance()
    this.getBtntCoinBalance()
    this.getBtntBscCoinBalance()
  }

  connectMetamask() {
    this.web3serviceService.openMetamask().then((data)=>{
      if (data !== false) {
        this.chainId = data.chainId;
        if (this.chainId != FOREIGN_CHAIN_ID) {
          this.disabled = true;
          console.log(this.disabled, "this.disabled");
          
        }
      }
    })
  }

  async getNativeCoinPrice() {
   await (await this.web3serviceService.getNativeCoinPrice()).subscribe((data: any) =>  {
     console.log("data", data);
      this.nativeCoinPrice = data.ethereum.usd;
    })
  }

  async getNativeCoinBalance() {
     await this.web3serviceService.getNativeCoinBalance().then((balanceData: any) =>  {
      console.log(balanceData);
       this.nativeCoinBalance = Web3.utils.fromWei(balanceData, 'ether');
     })
   }

   async getBtntCoinBalance() {
    await this.web3serviceService.getBtntCoinBalance().then((balanceData: any) =>  {
     console.log(Web3.utils.fromWei(balanceData, 'ether'));
      this.btntCoinBalance = Web3.utils.fromWei(balanceData, 'ether');
      this.btntCoinBalance = parseFloat(this.btntCoinBalance).toFixed(2);
    })
  }

  async getBtntBscCoinBalance() {
    await this.web3serviceService.getBtntBscCoinBalance().then((balanceData: any) =>  {
     console.log(Web3.utils.fromWei(balanceData, 'ether'));
      this.btntCoinBscBalance = Web3.utils.fromWei(balanceData, 'ether');
      this.btntCoinBscBalance = parseFloat(this.btntCoinBscBalance).toFixed(2);
    })
  }

  maxValue() {
    this.btntAmount = this.btntCoinBalance;
  }

  onChangeEvent(event: any){
    const pattern = /^[0-9]$/;
    console.log(event.target.value);
    this.btntAmount = event.target.value
    return pattern.test(event.key )
  }

  async executeTransfer(){
      console.log(this.btntAmount);
      if (this.disabled) {
        await this.web3serviceService.switchNetwork().then((data)=>{
              console.log(data);
              return
        })
      }
      this.btntTransferAmount = Web3.utils.toWei((this.btntAmount).toString(), 'ether')
      if (this.btntAmount == 0) {
        Swal.fire({
          icon: 'error',
          title: 'INVALID TRANSFER AMOUNT',
          text: 'Please Enter valid amount.'
        })
        return
      }
      Swal.showLoading()
      this.isDisbaled=true;
      await this.web3serviceService.executeTransfer(this.btntTransferAmount).then((txDetails: any) => {
        console.log(txDetails);
        Swal.fire(
          'Transaction Successful',
          `Foreign Transaction hash <br/>
          <a href="https://etherscan.io/tx/${txDetails.transactionHash}" target="_blank">${txDetails.transactionHash}</a> <br/>
          Just wait for while. To get BTNT on Binance Smart Chain <br/>`,
          'success'
        )
          //this.checkForTransaction(txDetails.transactionHash)
        this.isDisbaled=false;
      })
  }

  // async checkForTransaction(transactionHash: any){
  //   Swal.showLoading()
  //     this.isDisbaled=true;
  //     await this.web3serviceService.checkForTransaction(transactionHash).then((txDetails: any) => {

  //     })
  // }

  async addBscNetwork(){
    await this.web3serviceService.addBscNetwork().then((data)=>{

    })
  }

}
