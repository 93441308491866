<app-header></app-header>
<div class="grid-container">
  <div class="row">
    <div id="content" class="no-bottom no-top">
      <!-- <div id="top"></div> -->


      <!-- section begin -->
      <section data-bgcolor="#f9f9f9">
          <div class="container">
              <div class="row align-items-center">
                <div class="col-md-3"></div>
                  <div class="col-md-6 text-center">
                      <div class="bridge-wrapper">
                          <div>
                              <div class="bridge-tabs">
                                  <div class="bridge-tab_tab" id="defaultOpen">
                                    <button mat-raised-button color="primary" class="buttonBlock">Bridge Transfer</button>
                                  </div>
                                  <!-- <div class="bridge-tab_tab"> <button mat-raised-button color="primary" class="buttonBlock">BSC  <mat-icon>keyboard_arrow_right</mat-icon><mat-icon>keyboard_arrow_right</mat-icon> ETH</button></div> -->
                              </div>
                              <div class="bridge-body tabcontent" id="Deposit">
                                  <div class="from-label">From</div>
                                  <div class="from-section">
                                      <div class="chain-and-balance-details">
                                          <div class="chain-details">
                                              <img src="https://wallet-asset.matic.network/img/tokens/eth.svg"  height="24" width="24">
                                              <div class="chain-details__chain-name font-label-small">Ethereum chain</div>
                                          </div>
                                          <div class="font-label-extra-small">
                                              <span class="balance-details__label">Balance:  </span>
                                              <span class="">{{ btntCoinBalance }}  BTNT</span>
                                          </div>
                                      </div>
                                      <div class="token-and-amount-details">
                                          <div class="token-details">
                                              <img src="https://assets.coingecko.com/coins/images/2875/small/Logobit.jpg?1547037044" height="24" width="24" class="token-details__token-img">
                                              <div class="token-details__token-name">BitNautic (BTNT)</div>
                                          </div>
                                          <div class="amount-details">
                                              <input type="number" placeholder="0.00" [value]="btntAmount" (change)="onChangeEvent($event)" class="amount-details__textfield">
                                              <button mat-button color="primary" (click)="maxValue()">MAX</button>
                                          </div>
                                      </div>
                                  </div>
                                      <!-- <div class="down-arrow-section">
                                         <img src="images/arroww.png" >
                                      </div> -->
                                      <div class="to-label">To</div>
                                      <div class="to-chain-and-balance-details">
                                          <div class="chain-details">
                                             <img src="https://bitbill.oss-accelerate.aliyuncs.com/pics/coins/bsc.svg"  height="24" width="24">
                                          <div class="chain-details__chain-name font-label-small">Binance Smart chain</div> <br>
                                          <button mat-button class="networkButton" (click)=(addBscNetwork()) color="primary">Add BSC Network</button>
                                          </div>
                                          <div class="font-label-extra-small">
                                              <span class="balance-details__label">Balance:</span>
                                              <span class="balance-details__balance">{{ btntCoinBscBalance }} BTNT </span>
                                          </div>    
                                      </div>
                                  
                              </div>

                              <!-- <div class="bridge-body tabcontent" id="Withdraw">
                                  <div class="from-label">From</div>
                                  <div class="from-section">
                                      <div class="chain-and-balance-details">
                                          <div class="chain-details">
                                              <img src="https://wallet.polygon.technology/_nuxt/img/polygon-chain.854a6b8.svg" height="24" width="24">
                                              <div class="chain-details__chain-name font-label-small">Polygon chain</div>
                                          </div>
                                          <div class="font-label-extra-small">
                                              <span class="balance-details__label">Balance:</span>
                                              <span class="">0 ETH</span>
                                          </div>
                                      </div>
                                      <div class="token-and-amount-details">
                                          <div class="token-details">
                                              <img src="https://wallet-asset.matic.network/img/tokens/eth.svg" height="24" width="24" class="token-details__token-img">
                                              <div class="token-details__token-name">Ether</div>
                                              <img src="images/normal-arrow.png" height="24" width="24" class="token-details__arrow">
                                          </div>
                                          <div class="amount-details">
                                              <input type="text" placeholder="0.00" class="amount-details__textfield">
                                              <button class="amount-details__max-button">MAX</button>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="down-arrow-section">
                                      <img src="images/arroww.png">
                                  </div>
                                  <div class="to-label">To</div>
                                  <div class="to-chain-and-balance-details">
                                      <div class="chain-details">
                                          <img src="https://bitbill.oss-accelerate.aliyuncs.com/pics/coins/bsc.svg" height="24" width="24">
                                          <div class="chain-details__chain-name font-label-small">Binance Smart chain</div>
                                      </div>
                                      <div class="font-label-extra-small">
                                          <span class="balance-details__label">Balance:</span>
                                          <span class="balance-details__balance">0 ETH </span>
                                      </div>
                                  </div>
                                  
                              </div> -->
                          </div>
                          <div>
                            <button class="transfer-button" (click)=(executeTransfer())>{{disabled ? "Switch Network" : "Transfer"}}</button>
                         </div>
                      </div>
                  </div>
                  <div class="col-md-3"></div>
              </div>
          </div>
      </section>
  </div>
  </div>
</div>
