import { Injectable } from '@angular/core';
import { async } from '@angular/core/testing';
import Web3 from "web3";
import networkData from "../../assets/data/network.json";
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import ERC20_ABI from "../../assets/contracts/ERC20_ABI.json"
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';

declare const window: any;

const {
    HOME_RPC_URL,
    FOREIGN_RPC_URL,
    HOME_CHAIN_ID,
    FOREIGN_CHAIN_ID
  } = environment
@Injectable({
  providedIn: 'root'
})
export class Web3serviceService {
  window:any;
  isConnected: boolean = false;
  userWalletAddress: string;
  chainId: number;

  constructor(private http: HttpClient) { }
  
  
private getAccounts = async () => {
    try {
        return await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch (e) {
        return [];
    }
}

public openMetamask = async () => {
    if (typeof window.ethereum !== 'undefined') {
    window.web3 = new Web3(window.ethereum);
    let addresses = await this.getAccounts();
    let chainId = window.ethereum.networkVersion;
    if (!addresses.length) {
        try {
            addresses = await window.ethereum.enable();
        } catch (e) {
            return false;
        }
    }
    this.isConnected = true;
    this.userWalletAddress = addresses.length ? addresses[0] : null;
    this.chainId = chainId;
    window.ethereum.on('accountsChanged', function (accounts) {
        console.log('accountsChanges',accounts);
        this.userWalletAddress = accounts[0]
        window.location.reload();
      });
       // detect Network account change
      window.ethereum.on('networkChanged', function(networkId){
        console.log('networkChanged',networkId);
        this.chainId = networkId;
        window.location.reload();
      });
    return {
        address: this.userWalletAddress,
        chainId: this.chainId
      }
} else {
    return false
}
};

async getNativeCoinPrice():Promise<Observable<any>> {
    let chainId = window.ethereum.networkVersion;
    return await this.http.get(`https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`);
}

async getNativeCoinBalance() {
    window.web3 = new Web3(window.ethereum);
    let addresses = await this.getAccounts();
    return await window.web3.eth.getBalance(addresses[0])
}
 
async getBtntCoinBalance() {
    window.web3 = new Web3(FOREIGN_RPC_URL);
    const contract = new window.web3.eth.Contract(ERC20_ABI, environment.BTNT_TOKEN_ADDRESS);
    let addresses = await this.getAccounts();
    const btntBalance = await contract.methods.balanceOf(addresses[0]).call();
    return btntBalance
}

async getBtntBscCoinBalance() {
    window.web3 = new Web3(HOME_RPC_URL);
    const contract = new window.web3.eth.Contract(ERC20_ABI, environment.BTNT_TOKEN_BSC_ADDRESS);
    let addresses = await this.getAccounts();
    const btntBscBalance = await contract.methods.balanceOf(addresses[0]).call();
    return btntBscBalance
}

async executeTransfer(btntAmount){
    window.web3 = new Web3(window.ethereum);
    const contract = new window.web3.eth.Contract(ERC20_ABI, environment.BTNT_TOKEN_ADDRESS);
    let addresses = await this.getAccounts();
    const btntTransfer = await contract.methods.transfer(environment.FOREIGN_BRIDGE_ADDRESS, btntAmount).send({
        from: addresses[0]
    })
    return btntTransfer;
}

async switchNetwork(){
    // window.web3 = new Web3(window.ethereum);
    const data = [{
        chainId: "0x1"
    }]
    const tx = await window.ethereum.request({method: 'wallet_switchEthereumChain', params:data}).catch()
    if (tx) {
        console.log(tx)
    }
}

async addBscNetwork(){
    // window.web3 = new Web3(window.ethereum);
    const data = [{
        chainId: '0x38',
        chainName: 'Binance Smart Chain',
        nativeCurrency:
            {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com/'],
    }]
    const tx = await window.ethereum.request({method: 'wallet_addEthereumChain', params:data}).catch()
    if (tx) {
        console.log(tx)
    }
}


}
