// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  INFURA_KEY_URL: "",
  BTNT_TOKEN_ADDRESS: "0xC45DbdF28844fdB1482C502897d433aC08d6cCd0",
  BTNT_TOKEN_BSC_ADDRESS: "0xB9c7099D2FBbF82314de08045745DaF951CDDa85",
  HOME_CHAIN_ID: 56,
  HOME_NETWORK_NAME: "Binance Main Network",
  FOREIGN_NETWORK_NAME: "Ethereum Main Network",
  FOREIGN_CHAIN_ID: 1,
  FOREIGN_BRIDGE_ADDRESS: "0xD106d27F3c7fFbC9BB01F22517F6115E3893933a",
  HOME_BRIDGE_ADDRESS: "0x8Ae964F457Ad8F7Fd6f3E37fbCB39Da5a06e0872",
  HOME_RPC_URL: "https://bsc-dataseed.binance.org/",
  FOREIGN_RPC_URL: "https://mainnet.infura.io/v3/459dc464124940e98d79ed22f5fc59a0"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
