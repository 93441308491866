import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Web3serviceService } from "../../service/web3service.service";
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

const {
  HOME_CHAIN_ID,
  FOREIGN_CHAIN_ID,
  HOME_NETWORK_NAME,
  FOREIGN_NETWORK_NAME
} = environment

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  constructor(private web3serviceService:Web3serviceService) { }
  isConnected: boolean = false;
  userAddress: any;
  chainId: any;
  chainName: any;

  ngOnInit(): void {
    this.isConnected = false;
    this.connectMetamask()
  }

  connectMetamask() {
    this.web3serviceService.openMetamask().then((data)=>{
      if (data !== false) {
        console.log("data", data)
        this.isConnected = true
        this.chainId = data.chainId;
        this.userAddress = data.address.match(/.{1,4}/g)
        this.chainName = "ETHEREUM NETWORK";
      if (this.chainId != FOREIGN_CHAIN_ID && this.chainId != HOME_CHAIN_ID) {
        this.chainName = "WRONG NETWORK";
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Wrong Network!',
          })
    }
      } else {
        Swal.fire({
          title: 'Ooops!',
          text: 'Connect With Metamask',
          icon: 'error',
          confirmButtonText: 'Okay',
          footer: '<a href="https://metamask.io/download.html" target="_blank">Do you want to install Metamask?</a>'
        })
      }
      
      
    })
  }

}
