<p>
    <mat-toolbar class="toolbar">
      <!-- <button mat-icon-button class="icon" aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
      </button> -->
      <span>
        <img src="https://bitnautic.io/public/images/bitnautic_logo_blue_small.png" alt="" width="50px">
        BitNautic
      </span>
      <span class="spacer"></span>
      <!-- <button mat-icon-button class="icon favorite-icon" aria-label="Example icon-button with heart icon">
        <mat-icon>favorite</mat-icon>
      </button> -->
      <mat-chip-list class="matChipList" aria-label="Fish selection">
        <mat-chip class="matChipList" selected>{{chainName}}</mat-chip>
      </mat-chip-list>

      <button mat-raised-button (click)="connectMetamask()" color="primary" *ngIf="!isConnected">Connect Wallet</button>
      <button mat-raised-button color="primary" *ngIf="isConnected">Connected({{userAddress[0]}}...{{userAddress[9]}}{{userAddress[10]}})</button>
    </mat-toolbar>

  </p>